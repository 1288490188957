<template>
	<div>
		<button
			@click="popupShow()"
			style="    border: 8px solid rgb(177 179 183);
    padding: 14px 14px;
    border-radius: 12px;
}"
		>
			<span class="fa fa-camera" style="font-size:28px; color: rgb(177 179 183)"></span>
		</button>

		<!-- Modal Confirm -->
		<modal :visible="confirmModal" width="830">
			<v-card class="pa-4 text-center">
				<h2 class="mb-5">ถ่ายรูป</h2>
				<div class="d-flex">
					<video :id="id" width="400" height="300" autoplay></video>
					<canvas :id="canvasId" width="400" height="300"></canvas>
				</div>
				<v-spacer></v-spacer>
				<button class="btn btn-primary-o radius-20 w-100 mt-2" :disabled="loading" @click="onSubmit">ถ่าย</button>
				<v-spacer v-if="dataimage !== ''"></v-spacer>
				<button class="btn btn-primary-o radius-20 w-100 mt-2" v-if="dataimage !== ''" @click="onUpload">อัปโหลด</button>
				<v-spacer></v-spacer>
				<button class="btn btn-info radius-20 w-100 mt-2" @click="confirmModal = false">ยกเลิก</button>
				<v-spacer></v-spacer>
			</v-card>
		</modal>
	</div>
</template>

<script>
// import { list, remove, create, update, show } from "@/apis/admin/audit.js";

import moment from "moment";
import "@/styles/theme.scss";
import axios from "axios";

const _URL = window.URL || window.webkitURL;
export default {
    props:["id","canvasId"],
	data() {
		return {
			confirmModalCancel: false,
			confirmModal: false,
			formModal: false,
			loading: true,
			is_select: "",
			formDirty: false,
			formData: {},
			VUE_APP_LIFF: process.env.VUE_APP_LIFF,
			error_duplicate: false,
			dataimage: "",
			upload_url: process.env.VUE_APP_API_END_POINT + "/api/upload",
            localstream:null
		};
	},
	mounted() {
		this.loading = false;
	},
	methods: {
		async onSubmit() {
			// Elements for taking the snapshot
			var canvas = document.getElementById(this.canvasId);
			//   canvas.style.display = "block"
			var context = canvas.getContext("2d");
			var video = document.getElementById(this.id);
			const img = context.drawImage(video, 0, 0, 400, 300);
			this.dataimage = canvas.toDataURL("image/jpeg");
              this.localstream.stop();
			// video.style.display = "none"
		},
		async onUpload() {
			this.upload_url;
			axios
				.post(
					this.upload_url,
					{
						base64: this.dataimage,
					},
					{
						headers: {
							Authorization: this.token || "",
						},
					}
				)
				.then((response) => {
					this.$emit("input", response.data.data);
					this.$emit("apiResponse", response.data);
					this.loading = false;
					this.confirmModal = false;
				})
				.catch((err) => {
					console.log(err);
					// if (err.response.data) {
					// 	this.$emit("errorResponse", err.response.data);
					// }
					this.loading = false;
				});
		},
		async popupShow() {
			this.confirmModal = true;
			// Grab elements, create settings, etc.
			setTimeout(() => {
				var video = document.getElementById(this.id);
				console.log(video);
				// Get access to the camera!
				if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
					// Not adding `{ audio: true }` since we only want video now
					navigator.mediaDevices.getUserMedia({ video: true }).then(function (stream) {
						//video.src = window.URL.createObjectURL(stream);
						video.srcObject = stream;
						video.play();
					});
				}
			}, 1000);
		},
	},
};
</script>
<style scoped>
.icon-ab {
	position: absolute;
	right: 10px;
	top: 10px;
}
.icon-copy {
	cursor: pointer;
}
</style>
