import axios from "axios"

export const service = axios.create({
    baseURL : process.env.VUE_APP_API_END_POINT,
    timeout : 30000
})
const token = localStorage.getItem("token")
service.interceptors.request.use(
    config => {

            config.headers.Authorization = token
        
        return config
    } ,
    error => {
        return Promise.reject(error)
    }
)
