<template>
<div>
    <v-select
        no-data-text="ไม่พบข้อมูล"
        :items="options"
        :label="placeholder"
        :class="classes"
        :disabled="disabled"
        :multiple="multiple"
        :chips="chips"
        v-model="selected"
        v-bind="$attrs"
        dense
        solo
        hide-details
        flat
    >
        <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
            <slot :name="name" v-bind="slotData" />
        </template>
    </v-select>
    <span class="message-error" v-if="error && errorMessage">{{errorMessage}}</span>
</div>
</template>

<script>
export default {
    inheritAttrs: false,
    props: ['options', 'placeholder', 'value', 'formDirty', 'error', 'errorMessage', 'disabled', 'multiple', 'chips'],
    computed: {
        selected: {
            get() {return this.value},
            set(v) {this.$emit('input', v)}
        },
        classes() {
            return {
                'select-options': true,
                'select-options-error': this.error,
            };
        },
    },
}
</script>

<style scoped>
.message-error {
    color: #F64E60;
    font-weight: 400;
    font-size: 12px !important;
    display: block;
    margin-top: 4px;
}
</style>