<template>
    <quill-editor
        ref="myTextEditor"
        class="editor-container"
        v-model="content"
        :options="editorOption"
        @change="onEditorChange($event)"
    />
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.snow.css'

export default {
    props: ['value', 'placeholder'],
    components: {
        quillEditor,
    },
    data() {
        const toolbarOptions = [ [{ 'header': [1, 2, 3, 4, 5, 6, false] }], ['bold', 'italic', 'underline', 'strike'], [{ 'list': 'ordered'}, { 'list': 'bullet' }], [{ 'indent': '-1'}, { 'indent': '+1' }], [{ 'color': [] }, { 'background': [] }], ['clean']  ];
        return {
            name: 'app',
            content: '',
            editorOption: {
                theme: 'snow',
                modules: {
                    toolbar: toolbarOptions
                },
                placeholder: this.placeholder || '',
            }
        }
    },
    mounted() {
        if(this.value) {
            this.content = this.value
        }
    },
    methods: {
        onEditorChange(event) {
            this.$emit('onChange', event.html)
        }
    },
}
</script>

<style lang="scss" scoped>
.editor-container {
    height: 300px;
}
</style>