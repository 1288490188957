import Vue from 'vue'

///layouts
import Layout from '@/components/layouts/Layout'
// import LayoutPlain from '@/components/layouts/LayoutPlain'


///components
import AddLink from '@/components/general/AddLink'
import BaseDatePicker from '@/components/general/BaseDatePicker'
import BaseDatePickerRange from '@/components/general/BaseDatePickerRange'
import BaseTimePicker from '@/components/general/BaseTimePicker'
import BaseSelectOption from '@/components/general/BaseSelectOption'
import DataTable from '@/components/general/DataTable'
import Loading from '@/components/general/Loading'
import BaseInput from '@/components/general/BaseInput'
import BaseTextArea from '@/components/general/BaseTextArea'
import Modal from '@/components/general/Modal'
import UploadImagePreview from '@/components/general/UploadImagePreview'
import Texteditor from '@/components/general/Texteditor'
import Camera from '@/components/general/Camera'

Vue.component('layout', Layout)
// Vue.component('layout-plain', LayoutPlain)


Vue.component('add-link', AddLink)
Vue.component('base-date-picker', BaseDatePicker)
Vue.component('base-time-picker', BaseTimePicker)
Vue.component('base-date-picker-range', BaseDatePickerRange)
Vue.component('base-select-option', BaseSelectOption)
Vue.component('data-table', DataTable)
Vue.component('loading', Loading)
Vue.component('base-input', BaseInput)
Vue.component('base-text-area', BaseTextArea)
Vue.component('modal', Modal)
Vue.component('upload-image-preview', UploadImagePreview)
Vue.component('text-editor', Texteditor)
Vue.component('camera', Camera)

