<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="time"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="time"
                placeholder="HH:mm"
                readonly
                single-line
                outlined
                hide-details
                dense
                v-bind="attrs"
                v-on="on"
                :class="{'input-error': formDirty && !time}"
                append-icon="mdi-clock-time-four-outline"
            ></v-text-field>
        </template>
        <v-time-picker
            v-if="menu"
            v-model="time"
            @input="dataEscolhida"
            full-width
            format="24hr"
            @click:minute="$refs.menu.save(time)"
        ></v-time-picker>
      </v-menu>
</template>

<script>
export default {
    props: [ 'value' ],
    data() {
        return {
            time: null,
            menu: false,
            formDirty: false,
        }
    },
    mounted() {
        if(this.value) {
            this.time = this.value
        }
    },
    computed: {
        classes() {
            return {
                'input-group': true,
                'input-group-error': this.error,
            };
        },
    },
    methods: {
        dataEscolhida() {
            this.$emit('input', this.time);
        },
    },
}
</script>