var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-mobile-fixed subheader-fixed aside-enabled aside-fixed aside-minimize-hoverablet"},[_c('div',{staticClass:"sidebar-wrap aside aside-left aside-fixed d-flex flex-column flex-row-auto"},[_c('ul',{staticClass:"sidebar-menu"},[_c('li',[_c('span',{staticClass:"nav-section-title"},[_c('img',{staticClass:"img-logo",attrs:{"src":_vm.logo,"alt":""}})])]),_vm._l((_vm.menuItems[0].items),function(menu){return _c('li',{key:menu.label,class:(menu.children.length > 0 ? 'have-children' : '') + " " + (menu.label === _vm.currentPage ? 'active' : '')},[_c('a',{class:menu.children.length === 0 && menu.label === _vm.currentPage ? 'none-sub' : '',on:{"click":function () {
							if (menu.to !== '#') {
								return _vm.$router.push(menu.to);
							}
						}}},[_c('span',{staticClass:"fa",class:menu.icon}),_vm._v(" "+_vm._s(menu.label))]),(menu.children.length > 0)?_c('ul',{staticClass:"sidebar-menu-sub",style:(menu.label === _vm.currentPage ? 'display:block;' : 'display:none;')},_vm._l((menu.children),function(submenu){return _c('li',{key:submenu.label,class:submenu.children.length > 0 ? 'have-children-sub' : 'no-have-children-sub'},[_c('a',{on:{"click":function () {
									if (submenu.to !== '#') {
										return _vm.$router.push(submenu.to);
									}
								}}},[_c('img',{attrs:{"src":submenu.label === _vm.currentSubPage && menu.label === _vm.currentPage
										? require('@/assets/images/radio2.png')
										: require('@/assets/images/radio1.png'),"name":submenu.label === _vm.currentSubPage ? 'active' : 'not-active',"data":submenu.label === _vm.currentSubPage ? 'is-active' : '',"alt":"","srcset":""}}),_vm._v(" "+_vm._s(submenu.label))]),(submenu.children.length > 0)?_c('ul',{style:(submenu.label === _vm.currentSubPage ? 'display:block;' : 'display:none;')},_vm._l((submenu.children),function(submenu2){return _c('li',{key:submenu2.label,class:submenu2.label === _vm.currentSubInPage &&
									submenu.label === _vm.currentSubPage &&
									menu.label === _vm.currentPage
										? 'sub-active'
										: ''},[_c('a',{on:{"click":function($event){return _vm.$router.push(submenu2.to)}}},[_vm._v(_vm._s(submenu2.label))])])}),0):_vm._e()])}),0):_vm._e()])}),_c('div',{staticClass:"btn-logout",on:{"click":function () {
						_vm.confirmLogout = true;
					}}},[_vm._v(" Logout ")])],2)]),_c('div',{staticClass:"d-flex flex-column flex-row-fluid wrapper"},[_c('div',{staticClass:"layout-header"},[(_vm.isMobile)?_c('v-app-bar-nav-icon',{staticClass:"mr-6",on:{"click":function($event){$event.stopPropagation();_vm.mobileDrawer = !_vm.mobileDrawer}}}):_vm._e(),_c('div',{staticClass:"header-title"},[_c('span',{staticClass:"mr-4"},[_vm._t("icon")],2),_c('div',{staticClass:"font-size-6"},[_vm._v(_vm._s(_vm.title))])]),_c('div',[_vm._t("center")],2),_c('div',[_vm._t("right")],2)],1),_c('div',{staticClass:"layout-body"},[_c('div',{staticClass:"main-content"},[_vm._t("default")],2)]),(_vm.hasButton)?_c('div',{staticClass:"layout-footer"},[_vm._t("footer")],2):_vm._e()]),_c('modal',{attrs:{"visible":_vm.confirmLogout,"width":"400"}},[_c('v-card',{staticClass:"pa-4 text-center"},[_c('h3',{staticClass:"mb-5"},[_vm._v("ยืนยันการออกจากระบบ ?")]),_c('div',{staticClass:"d-flex align-center justify-center mt-6"},[_c('button',{staticClass:"btn btn btn-primary-o mt-bg-color-red-2 color-white mr-4 px-6",attrs:{"type":"button"},on:{"click":function($event){_vm.confirmLogout = false}}},[_vm._v(" ยกเลิก ")]),_c('button',{staticClass:"btn btn-info bg-color-green-2 color-white px-6",attrs:{"type":"button"},on:{"click":_vm.logoutConfirm}},[_vm._v(" ยืนยัน ")])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }