<template>
    <div class="loading-data" v-if="loading">
        <div class="loader-table"></div>
    </div>
    <div class="responsive-table" v-else>
        <div class="table-wrap" :style="`min-width: ${minWidth ? minWidth : '700px'};`" :class="classWrap">
            <v-data-table
                :headers="headers"
                :items="data.rows"
                :page.sync="paginate.page"
                :items-per-page="filter.limit"
                hide-default-footer
                @page-count="paginate.pageCount = $event"
                v-model="selected"
                :show-select="showSelect"
                :mobile-breakpoint="0"
                no-data-text="No data found"
                @click:row="handleClick"
            >
                <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
                    <slot :name="name" v-bind="slotData" />
                </template>
            </v-data-table>
        </div>
        <div class="reletive d-flex align-items-center justify-content-between" :style="`min-width: ${minWidth ? minWidth : '700px'};`" v-if="data.rows.length !== 0">
            <v-pagination
                v-model="paginate.page"
                :length="paginationLength"
                :total-visible="10"
            />
            <div class="d-flex align-items-center custom-page" v-if="!hideLimit">
                <div style="min-width: 100px; margin-right: 28px;">Rows per page:</div>
                <v-select
                    @change="onItemsPerPageChange"
                    :items="items"
                    v-model="paginate.itemsPerPage"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['headers', 'data', 'filter', 'hideLimit', 'value', 'showSelect', 'minWidth', 'classWrap', 'loading'],
    data() {
        return {
            selected: [],
            items: [5, 10, 20, 50, 100],
            paginate: {
                page: 1,
                itemsPerPage: 10,
                pageCount: 0,
                offset: 0,
            },
        }
    },
    computed: {
        paginationLength() {
            this.paginate.itemsPerPage = this.filter.limit
            if(this.data.total) {
                const result = Math.ceil(this.data.total / this.filter.limit)
                return result
            }
        }
    },
    watch: {
        'paginate.page': function(page) {
            console.log(page);
            const offset = (this.paginate.page - 1) * this.paginate.itemsPerPage
            this.paginate.offset = offset
            this.$emit('change', offset, this.paginate.itemsPerPage)
                console.log(this.paginate.page);
        },
        'selected': function(users) {
            this.$emit('selected', users)
        },
    },
    methods: {
        onItemsPerPageChange() {
        
            if(this.paginate.page !== 1) {
                this.paginate.page = 1
            }
            else {
                this.$emit('change', this.paginate.offset, this.paginate.itemsPerPage)
            }
        },
        handleClick(value) {
            this.$emit('clickRow', value)
        }
    },
}
</script>

<style>
.table-wrap .symbol img {
    object-fit: contain;
}
.responsive-table {
    overflow: auto;
}
.table-wrap.vertical-top tbody>tr {
    vertical-align: top !important;
}
.table-wrap tbody>tr>td {
    padding: 18px 16px !important;
}
.loading-data {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 200px;
}
.loader-table  {
    animation: rotate 1s infinite;  
    height: 50px;
    width: 50px;
}

.loader-table:before,
.loader-table:after {   
    border-radius: 50%;
    content: '';
    display: block;
    height: 20px;  
    width: 20px;
}
.loader-table:before {
    animation: ball1 1s infinite;  
    background-color: #27BA08;
    box-shadow: 30px 0 0 #27BA08;
    margin-bottom: 10px;
}
.loader-table:after {
    animation: ball2 1s infinite; 
    background-color: #27BA08;
    box-shadow: 30px 0 0 #27BA08;
}

@keyframes rotate {
    0% { 
        -webkit-transform: rotate(0deg) scale(0.8); 
        -moz-transform: rotate(0deg) scale(0.8);
    }
    50% { 
        -webkit-transform: rotate(360deg) scale(1.2); 
        -moz-transform: rotate(360deg) scale(1.2);
    }
    100% { 
        -webkit-transform: rotate(720deg) scale(0.8); 
        -moz-transform: rotate(720deg) scale(0.8);
    }
}

@keyframes ball1 {
    0% {
        box-shadow: 30px 0 0 #27BA08;
    }
    50% {
        box-shadow: 0 0 0 #27BA08;
        margin-bottom: 0;
        -webkit-transform: translate(15px,15px);
        -moz-transform: translate(15px, 15px);
    }
    100% {
        box-shadow: 30px 0 0 #27BA08;
        margin-bottom: 10px;
    }
}

@keyframes ball2 {
    0% {
        box-shadow: 30px 0 0 #27BA08;
    }
    50% {
        box-shadow: 0 0 0 #27BA08;
        margin-top: -20px;
        -webkit-transform: translate(15px,15px);
        -moz-transform: translate(15px, 15px);
    }
    100% {
        box-shadow: 30px 0 0 #27BA08;
        margin-top: 0;
    }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .responsive-table {
        overflow: hidden;
    }
}
.custom-page {
        position: absolute;
    right:0px;
    align-items: center;
    top:-10px;
}
.reletive {position: relative;}
</style>