<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
        :close-on-click="false"
    >
        <template v-slot:activator="{ on }">
            <v-text-field
                :value="computedDateFormattedMomentjs"
                :placeholder="placeholder"
                v-on="on"
                readonly
                single-line
                outlined
                hide-details
                dense
                append-icon="mdi-calendar-month"
            ></v-text-field>
        </template>
        <v-date-picker
            v-model="dates"
            no-title
            header-color="green lighten-1"
            color="green lighten-1"
            range
            class="date-picker"
        >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="menu = false"
            >
                Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="saveDates"
            >
                OK
            </v-btn>
        </v-date-picker>
    </v-menu>
</template>

<script>
import moment from 'moment'

export default {
    props: ['placeholder', 'value', 'name'],
    data() {
        return {
            menu: false,
            dates: [],
        }
    },
    mounted() {
        if(this.value) {
            this.dates = [moment(this.value[0]).format('YYYY-MM-DD'), moment(this.value[1]).format('YYYY-MM-DD')]
        }
    },
    computed: {
        computedDateFormattedMomentjs () {
            return this.value.length > 0 ? moment(this.value[0]).format('DD/MM/YYYY') + ' - ' + moment(this.value[1]).format('DD/MM/YYYY') : ''
        },
    },
    methods: {
        formatDate(date) {
            const year = date.substring(0, 4)
            const month = date.substring(5, 7)
            const day = date.substring(8, 11)
            const hour = '00'
            const minute = '00'
            const second = '00'

            return year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':' + second + '.000' + 'Z'
        },
        dataEscolhida() {
            // console.log('this.dates.length', this.dates.length)
            
        },
        saveDates() {
            if(this.dates.length === 2) {
                if(Date.parse(this.dates[0]) < Date.parse(this.dates[1])) {
                    this.$emit('input', [this.formatDate(this.dates[0]), this.formatDate(this.dates[1])]);
                }
                else {
                    this.$emit('input', [this.formatDate(this.dates[1]), this.formatDate(this.dates[0])]);
                }
            }
            else {
                this.$emit('input', [this.formatDate(this.dates[0]), this.formatDate(this.dates[0])]);
            }
            this.menu = false
        }
    },
}
</script>

<style scoped>
.date-picker /deep/.v-date-picker-table {
    height: 227px;
}
.date-picker /deep/.v-picker__actions {
    padding: 0px 8px 8px;
}
</style>