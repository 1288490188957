<template>
<div>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
   
    >
        <template v-slot:activator="{ on }">
            <v-text-field
                :value="computedDateFormattedMomentjs"
                :placeholder="placeholder"
                v-on="on"
                readonly
                single-line
                hide-details
                dense
                solo
                class="text-field"
                :class="{'input-error': error}"
                append-icon="mdi-calendar-month"
                :disabled="readOnly"
            ></v-text-field>
        </template>
        <v-date-picker
            v-model="date"
            @input="dataEscolhida"
            ref="form-base-date_picker"
            no-title
            header-color="green lighten-1"
            color="green lighten-1"
            :min="min"
            :max="max"
          
        ></v-date-picker>
    </v-menu>
    <span
        class="message-error"
        v-if="error && errorMessage"
    >{{errorMessage}}</span>
</div>
</template>

<script>
import moment from 'moment'

export default {
    props: ['placeholder', 'value', 'error', 'errorMessage', 'readOnly', 'min', 'max', 'required', 'formDirty', 'disableDelete', 'name'],
    data() {
        return {
            menu: false,
            date: null
        }
    },
    mounted() {
        if(this.value) {
            this.date = moment.parseZone(this.value).format('YYYY-MM-DD')
        }
    },
    computed: {
        computedDateFormattedMomentjs () {
            return this.value ? moment.parseZone(this.value).format('DD/MM/YYYY') : ''
        },
        classes() {
            return {
                'input-group': true,
                'input-group-error': this.error,
            };
        },
    },
    methods: {
        formatDate(date) {
            const year = date.substring(0, 4)
            const month = date.substring(5, 7)
            const day = date.substring(8, 11)
            const hour = '00'
            const minute = '00'
            const second = '00'

            return year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':' + second + '.000' + 'Z'
        },
        dataEscolhida() {
            this.$emit('input', this.formatDate(this.date));
            this.menu = false;
        },
        handleInput(e) {
            if ( this.disableDelete && (e.key === "Backspace" || e.key === "Delete")) {
                return e.preventDefault(); // Don't do anything to the input
            }
        },
    },
}
</script>

<style scoped>
.input-group-error .form-control {
    border: 1px solid #FF4242;
}
.message-error {
    color: #FF4242;
    font-weight: 400;
    font-size: 12px !important;
    display: block;
    margin-top: 4px;
}
.text-field{
      font-size: 14px !important;
}
.v-text-field >.v-input__control>.v-input__slot:before {
    border-color: #90C143;
}
.v-text-field > .v-input__control > .v-input__slot {
    min-height: 33px !important;
}
.v-text-field--filled.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot {
    min-height: 33px !important;
}
</style>