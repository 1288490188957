<template>
<div>
    <input
        v-bind="$attrs"
        :type="type"
        :class="classes"
        :placeholder="placeholder"
        :value="value"
        @input="onInput"
        @blur="onOutfocus"
        @keypress="onKeypress"
        @keyup="onKeyUp"
    >
    <div class="input-detail">
        <div class="message-error">
            <span v-if="error">{{errorMessage}}</span>
        </div>
        <span
            v-if="counter"
            class="counter-number"
            :class="{'counter-number-error': error}"
        >{{value.length}}/{{counter}}</span>
    </div>
</div>
</template>

<script>
export default {
    name: 'base-input',
    props: {
        placeholder: {
            type: String,
            required: false,
        },
        error: {
            type: Boolean,
            required: false,
        },
        errorMessage: {
            type: String,
            required: false,
        },
        type: {
            type: String,
            required: false,
            default: 'text'
        },
        value: {
            type: [String, Number],
            required: false,
        },
        counter: {
            type: String,
            required: false,
        },
        name: {
            type: String,
            required: false,
        },
    },
    computed: {
        classes() {
            return {
                'input': true,
                'input-error': this.error,
            };
        },
    },
    methods: {
        onInput(event) {
            this.$emit('input', event.target.value)
        },
         onOutfocus(event) {
            this.$emit('focusout', event.target.value)
        },
        onKeypress(event) {
            this.$emit('onKeypress', event)
        },
        onKeyUp(event) {
            this.$emit('onKeyUp', event)
        }
    },
}
</script>

<style scoped>
.input {
    width: 100%;
    height: 33px;
    border: 1px solid #E2E5ED;
    background-color: #ffffff;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    color: #1E88E5;
    padding: 0 16px;
   line-height: 1.42857143;
    text-indent: 1px;
}
.input:focus {
    outline: none;
}
input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
input:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
input:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */
.input[readonly], input[disabled] .input {
    cursor: not-allowed;
    background-color: #eee;
    opacity: 1;
}
.input.input-error {
    border: 1px solid #FF4242;
}
.input::placeholder {
    color: #D0D0D0;
    line-height: 44px;
    text-indent: 1px;
}
.input:disabled {
    border-color: #D0D0D0;
    background-color: #F8F9FD;;
    color: #9D9EA4;
}
.input-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4px;
}
.message-error {
    color: #FF4242;
    font-weight: 400;
    font-size: 12px !important;
    display: block;
}
.counter-number {
    font-weight: 400;
    font-size: 12px !important;
    float: right;
    color: #9EA0A5;
}
.counter-number-error {
    color: #FF4242;
}
</style>