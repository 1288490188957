import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "index",
		component: () => import("../views/Index.vue"),
	},
	{
		path: "/tenko-history",
		name: "tenko history",
		component: () => import("../views/dashboard/TenkoHistory.vue"),
	},
	{
		path: "/truck-check-result",
		name: "truck check result",
		component: () => import("../views/dashboard/TruckCheckResult.vue"),
	},
	{
		path: "/register",
		name: "register",
		component: () => import("../views/auth/register.vue"),
	},
    {
		path: "/reset-password",
		name: "reset-password",
		component: () => import("../views/auth/password.vue"),
	},
    {
		path: "/forgot-password",
		name: "forgot-password",
		component: () => import("../views/auth/forgot-password.vue"),
	},
	{
		path: "/login",
		name: "login",
		component: () => import("../views/auth/login.vue"),
	},
	//Master Profile

	//project-plant
	{
		path: "/master-profile/project-plant/company",
		name: "Master Profile company",
		component: () => import("../views/master-profile/project-plant/company/list.vue"),
	},
    {
		path: "/master-profile/project-plant/company/form",
		name: "Master Profile company form",
		component: () => import("../views/master-profile/project-plant/company/form.vue"),
	},
	{
		path: "/master-profile/project-plant/project-plant",
		name: "Master Profile company project-plant",
		component: () => import("../views/master-profile/project-plant/project-plant/list.vue"),
	},
    {
		path: "/master-profile/project-plant/project-plant/form",
		name: "Master Profile company project-plant form",
		component: () => import("../views/master-profile/project-plant/project-plant/form.vue"),
	},
	
	//truck
    {
		path: "/master-profile/truck/type",
		name: "Master Profile truck type",
		component: () => import("../views/master-profile/truck/type/list.vue"),
	},
    {
		path: "/master-profile/truck/type/form",
        name: "Master Profile truck type form",
		component: () => import("../views/master-profile/truck/type/form.vue"),
	},
    {
		path: "/master-profile/truck/profile",
		name: "Master Profile truck profile",
		component: () => import("../views/master-profile/truck/profile/list.vue"),
	},
    {
		path: "/master-profile/truck/profile/form",
        name: "Master Profile truck profile form",
		component: () => import("../views/master-profile/truck/profile/form.vue"),
	},
    {
		path: "/master-profile/driver",
        name: "Master Profile driver profile",
		component: () => import("../views//master-profile/driver/list.vue"),
	},
    {
		path: "/master-profile/driver/form",
        name: "Master Profile driver profile form",
		component: () => import("../views//master-profile/driver/form.vue"),
	},
	{
		path: "/master-profile/truck/checklist",
		name: "Master Profile truck checklist",
		component: () => import("../views/master-profile/truck/checklist/List.vue"),
	},
	{
		path: "/master-profile/truck/checklist/form",
		name: "Master Profile truck checklist form",
		component: () => import("../views/master-profile/truck/checklist/Form.vue"),
	},
	{
		path: "/master-profile/truck/checklist/detail",
		name: "Master Profile truck checklist Detail",
		component: () => import("../views/master-profile/truck/checklist/detail/List.vue"),
	},
	{
		path: "/master-profile/truck/checklist/detail/form",
		name: "Master Profile truck checklist Detail Form",
		component: () => import("../views/master-profile/truck/checklist/detail/Form.vue"),
	},

	//audit truck
	{
		path: "/master-profile/audit/truck-checklist",
		name: "Master Profile audit truck checklist",
		component: () => import("../views/master-profile/audit/trunk-checklist/List.vue"),
	},
	{
		path: "/master-profile/audit/truck-checklist/form",
		name: "Master Profile audit truck checklist form",
		component: () => import("../views/master-profile/audit/trunk-checklist/Form.vue"),
	},
	{
		path: "/master-profile/audit/truck-checklist/detail",
		name: "Master Profile audit truck checklist Detail",
		component: () => import("../views/master-profile/audit/trunk-checklist/detail/List.vue"),
	},
	{
		path: "/master-profile/audit/truck-checklist/detail/form",
		name: "Master Profile audit truck checklist Detail Form",
		component: () => import("../views/master-profile/audit/trunk-checklist/detail/Form.vue"),
	},
	//audit driver
	{
		path: "/master-profile/audit/driver-checklist",
		name: "Master Profile audit driver checklist",
		component: () => import("../views/master-profile/audit/driver-checklist/List.vue"),
	},
	{
		path: "/master-profile/audit/driver-checklist/form",
		name: "Master Profile audit driver checklist form",
		component: () => import("../views/master-profile/audit/driver-checklist/Form.vue"),
	},
	{
		path: "/master-profile/audit/driver-checklist/detail",
		name: "Master Profile audit driver checklist Detail",
		component: () => import("../views/master-profile/audit/driver-checklist/detail/List.vue"),
	},
	{
		path: "/master-profile/audit/driver-checklist/detail/form",
		name: "Master Profile audit driver checklist Detail Form",
		component: () => import("../views/master-profile/audit/driver-checklist/detail/Form.vue"),
	},

	// Admin

	{
		path: "/admin/super-admin",
		name: "Admin super-admin",
		component: () => import("../views/admin/super-admin/list.vue"),
	},
	{
		path: "/admin/super-admin/form",
		name: "Admin super-admin form",
		component: () => import("../views/admin/super-admin/form.vue"),
	},
    {
		path: "/admin/driver",
		name: "Admin driver",
		component: () => import("../views/admin/driver/list.vue"),
	},
	{
		path: "/admin/driver/form",
		name: "Admin driver form",
		component: () => import("../views/admin/driver/form.vue"),
	},
    {
		path: "/admin/audit",
		name: "Admin audit",
		component: () => import("../views/admin/audit/list.vue"),
	},
	{
		path: "/admin/audit/form",
		name: "Admin audit form",
		component: () => import("../views/admin/audit/form.vue"),
	},
    {
		path: "/admin/supervisor",
		name: "Admin supervisor",
		component: () => import("../views/admin/supervisor/list.vue"),
	},
	{
		path: "/admin/supervisor/form",
		name: "Admin supervisor form",
		component: () => import("../views/admin/supervisor/form.vue"),
	},

    {
		path: "/rediness/driver",
		name: "rediness driver",
		component: () => import("../views/rediness/driver/list.vue"),
	},
	{
		path: "/rediness/driver/form",
		name: "rediness driver form",
		component: () => import("../views/rediness/driver/form.vue"),
	},
    {
		path: "/camera-demo",
		name: "demo",
		component: () => import("../views/camera.vue"),
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;
