import { service } from "./axios"

const request = async(instance , { cbSuccess , cbError }) => {
    try {
        const res = await instance
        if(res && res.status === 200 ) {
            cbSuccess(res)
        }
        else {
            cbError(res , "Error !")
        }
    } catch( e ) {
        console.log(e)
        cbError(e)
    }
}
export const login = ({ payload, cbSuccess , cbError }) => request(
    service.post(`/api/cms/register/login`, payload) ,
    { cbSuccess , cbError }
  )
  export const sign_up = ({ payload, cbSuccess , cbError }) => request(
    service.post(`/api/cms/register/sign-up`, payload) ,
    { cbSuccess , cbError }
  )
  export const sendmail = ({ payload, cbSuccess , cbError }) => request(
    service.post(`/api/cms/register/send-mail`, payload) ,
    { cbSuccess , cbError }
  )
  export const forget_password = ({ payload, cbSuccess , cbError }) => request(
    service.post(`/api/cms/register/forget-password`, payload) ,
    { cbSuccess , cbError }
  )
  export const reset_password = ({ payload, cbSuccess , cbError }) => request(
    service.post(`/api/cms/register/reset-password`, payload) ,
    { cbSuccess , cbError }
  )
  export const check_active = ({ payload, cbSuccess , cbError }) => request(
    service.post(`/api/cms/register/check`, payload) ,
    { cbSuccess , cbError }
  )
