<template>
    <router-link :to="to" class="d-flex align-center add-btn" v-if="to">
        <v-btn
            elevation="0"
            class="mr-2"
            depressed
        >
            <v-icon>
                mdi-plus-circle-outline
            </v-icon>
        </v-btn>
        <span class="mb-0">{{ label }}</span>
    </router-link>
    <div class="d-flex align-center add-btn" @click="$emit('click')" v-else>
        <div class="btn mr-4">
            <v-icon>
                mdi-plus-circle-outline
            </v-icon>
        </div>
        <div class="h5 text-dark mb-0">{{ label }}</div>
    </div>
</template>

<script>
export default {
    props: ['to', 'label']
}
</script>

<style scoped>
.add-btn {
    cursor: pointer;
    width: fit-content;
    text-decoration: none;
    font-size: 14px;
    font-weight: 700;
    color: #000000;
    padding-right: 12px;
}
</style>