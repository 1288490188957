<template>
	<div class="header-mobile-fixed subheader-fixed aside-enabled aside-fixed aside-minimize-hoverablet">
		<div class="sidebar-wrap aside aside-left aside-fixed d-flex flex-column flex-row-auto">
			<ul class="sidebar-menu">
				<li>
					<span class="nav-section-title">
						<img :src="logo" class="img-logo" alt="" />
					</span>
				</li>
				<li
					v-for="menu in menuItems[0].items"
					:key="menu.label"
					:class="`${menu.children.length > 0 ? 'have-children' : ''} ` + (menu.label === currentPage ? 'active' : '')"
				>
					<a
						@click="
							() => {
								if (menu.to !== '#') {
									return $router.push(menu.to);
								}
							}
						"
						:class="menu.children.length === 0 && menu.label === currentPage ? 'none-sub' : ''"
						><span class="fa" :class="menu.icon"></span> {{ menu.label }}</a
					>
					<ul
						v-if="menu.children.length > 0"
						class="sidebar-menu-sub"
						:style="menu.label === currentPage ? 'display:block;' : 'display:none;'"
					>
						<li
							v-for="submenu in menu.children"
							:key="submenu.label"
							:class="submenu.children.length > 0 ? 'have-children-sub' : 'no-have-children-sub'"
						>
							<a
								@click="
									() => {
										if (submenu.to !== '#') {
											return $router.push(submenu.to);
										}
									}
								"
								><img
									:src="
										submenu.label === currentSubPage && menu.label === currentPage
											? require('@/assets/images/radio2.png')
											: require('@/assets/images/radio1.png')
									"
									:name="submenu.label === currentSubPage ? 'active' : 'not-active'"
									:data="submenu.label === currentSubPage ? 'is-active' : ''"
									alt=""
									srcset=""
								/>
								{{ submenu.label }}</a
							>
							<ul
								v-if="submenu.children.length > 0"
								:style="submenu.label === currentSubPage ? 'display:block;' : 'display:none;'"
							>
								<li
									v-for="submenu2 in submenu.children"
									:key="submenu2.label"
									:class="
										submenu2.label === currentSubInPage &&
										submenu.label === currentSubPage &&
										menu.label === currentPage
											? 'sub-active'
											: ''
									"
								>
									<a @click="$router.push(submenu2.to)">{{ submenu2.label }}</a>
								</li>
							</ul>
						</li>
					</ul>
				</li>

				<div
					class="btn-logout"
					@click="
						() => {
							confirmLogout = true;
						}
					"
				>
					Logout
				</div>
			</ul>
		</div>
		<div class="d-flex flex-column flex-row-fluid wrapper">
			<div class="layout-header">
				<v-app-bar-nav-icon v-if="isMobile" @click.stop="mobileDrawer = !mobileDrawer" class="mr-6"></v-app-bar-nav-icon>
				<div class="header-title">
					<span class="mr-4">
						<slot name="icon" />
					</span>
					<div class="font-size-6">{{ title }}</div>
				</div>
				<div>
					<slot name="center"></slot>
				</div>
				<div>
					<slot name="right"></slot>
				</div>
			</div>
			<div class="layout-body">
				<div class="main-content">
					<slot />
				</div>
			</div>
			<div class="layout-footer" v-if="hasButton">
				<slot name="footer"></slot>
			</div>
		</div>
		<!-- Modal Confirm Logout -->
		<modal :visible="confirmLogout" width="400">
			<v-card class="pa-4 text-center">
				<h3 class="mb-5">ยืนยันการออกจากระบบ ?</h3>
				<div class="d-flex align-center justify-center mt-6">
					<button
						type="button"
						class="btn btn btn-primary-o mt-bg-color-red-2 color-white mr-4 px-6"
						@click="confirmLogout = false"
					>
						ยกเลิก
					</button>
					<button type="button" class="btn btn-info bg-color-green-2 color-white px-6" @click="logoutConfirm">
						ยืนยัน
					</button>
				</div>
			</v-card>
		</modal>
	</div>
</template>

<script>
import "../../styles/sidebar.scss";
import { check_active } from "@/apis/auth.js";
export default {
	props: ["hasButton", "title", "currentPage", "currentSubPage", "currentSubInPage"],
	data() {
		return {
			mobileDrawer: false,
			drawer: false,
			mini: true,
			confirmLogout: false,
			isMobile: false,
			group: null,
			userProfile: {
				first_name: "",
				last_name: "",
				line_picture_uri: "",
				name: "Test",
			},
			menuItems: [
				{
					title: "เมนูหลัก",
					items: [
						{
							icon: "fa-bar-chart",
							label: "Dashboard",
							to: "/",
							outside: false,
							children: [],
						},
						{
							icon: "fa-line-chart",
							label: "Tenko History",
							to: "/tenko-history",
							outside: false,
							children: [],
						},
						{
							icon: "fa-pie-chart",
							label: "Truck Result",
							to: "/truck-check-result",
							outside: false,
							children: [],
						},
						{
							icon: "fa-cubes",
							label: "Master Profile",
							to: "#",
							outside: false,
							children: [
								{
									icon: "",
									label: "Project & Plant",
									to: "#",
									children: [
										{
											icon: "",
											label: "Company",
											to: "/master-profile/project-plant/company",
										},
										{
											icon: "",
											label: "Project & Plant",
											to: "/master-profile/project-plant/project-plant",
										},
									],
								},
								{
									icon: "",
									label: "Truck",
									to: "#",
									children: [
										{
											icon: "",
											label: "Type",
											to: "/master-profile/truck/type",
										},
										{
											icon: "",
											label: "Profile",
											to: "/master-profile/truck/profile",
										},
										{
											icon: "",
											label: "Checklist",
											to: "/master-profile/truck/checklist",
										},
									],
								},
								{
									icon: "",
									label: "Driver",
									to: "/master-profile/driver",
									children: [],
								},
								{
									icon: "",
									label: "Audit",
									to: "#",
									children: [
										{
											icon: "",
											label: "Truck checklist",
											to: "/master-profile/audit/truck-checklist",
										},
										{
											icon: "",
											label: "Driver checklist",
											to: "/master-profile/audit/driver-checklist",
										},
									],
								},
							],
						},
						{
							icon: "fa-file-text",
							label: "Readiness",
							to: "#",
							outside: false,
							children: [
								{
									icon: "",
									label: "Driver",
									to: "/rediness/driver",
									children: [],
								},
							],
						},
						{
							icon: "fa-user",
							label: "Admin",
							to: "#",
							outside: false,
							children: [
								{
									icon: "",
									label: "Super Admin",
									to: "/admin/super-admin",
									children: [],
								},
								{
									icon: "",
									label: "Driver",
									to: "/admin/driver",
									children: [],
								},
								{
									icon: "",
									label: "Audit",
									to: "/admin/audit",
									children: [],
								},
								{
									icon: "",
									label: "Supervisor",
									to: "/admin/supervisor",
									children: [],
								},
							],
						},
					],
				},
			],
			logo: require("@/assets/images/logo-jwd.png"),
		};
	},
	async mounted() {
		//console.log("check active -------------> inactive");
		this.onResize();
		window.addEventListener("resize", this.onResize, { passive: true });
		const token = localStorage.getItem("token");
		const profile = localStorage.getItem("profile");

		if (token === null) {
			this.$router.push("/login");
		} else {
			await this.checkActive(token);
			const token_new = localStorage.getItem("token");
			const profile_new = localStorage.getItem("profile");
			const p = JSON.parse(profile_new);
			if (p.logo) {
				this.logo = p.logo;
			}
		}

		const cookies_mini = this.$cookies.get("cms_nt_mini_sidebar");
		if (this.$cookies.get("cms_nt_mini_sidebar")) {
			this.mini = cookies_mini === "true";
		} else {
			this.mini = false;
			this.$cookies.set("cms_nt_mini_sidebar", false);
		}
		$(document).ready(function () {
			$(".sidebar-menu > li.have-children > a").on("click", function (i) {
				//console.log("check");
				i.preventDefault();

				$(".sidebar-menu-sub > li.have-children-sub > a > img").attr("src", require("@/assets/images/radio1.png"));
				$(".sidebar-menu-sub > li.have-children-sub > a > img").attr("name", "not-active");

				if (!$(this).parent().hasClass("active")) {
					$(".sidebar-menu li ul").slideUp();
					$(this).next().slideToggle();
					$(".sidebar-menu li").removeClass("active");
					$(this).parent().addClass("active");
				} else {
					$(this).next().slideToggle();

					$(".sidebar-menu li").removeClass("active");
				}
			});

			$(".sidebar-menu-sub > li.have-children-sub > a").on("click", function (i) {
				i.preventDefault();
				if (!$(this).parent().hasClass("active")) {
					// $(".sidebar-menu-sub li ul").slideUp();
					var img = $(this).find("img");

					$(this).next().slideToggle();
					$(".sidebar-menu-sub li").removeClass("active");
					$(".sidebar-menu-sub > li > a > img").attr("src", require("@/assets/images/radio1.png"));

					$(".sidebar-menu-sub > li.have-children-sub > a > img").attr("src", require("@/assets/images/radio1.png"));

					if (img.attr("name") === "active") {
						img.attr("src", require("@/assets/images/radio1.png"));
						img.attr("name", "not-active");
					} else {
						img.attr("src", require("@/assets/images/radio2.png"));
						img.attr("name", "active");
					}
				} else {
					$(this).next().slideToggle();
					$(".sidebar-menu-sub li").removeClass("active");
					var img = $(this).find("img");

					if (img.attr("name") === "active") {
						img.attr("src", require("@/assets/images/radio1.png"));
						img.attr("name", "not-active");
					} else {
						img.attr("src", require("@/assets/images/radio2.png"));
						img.attr("name", "active");
					}
				}
			});
			$(".sidebar-menu-sub > li.no-have-children-sub > a").on("click", function (i) {
				i.preventDefault();
				if (!$(this).parent().hasClass("active")) {
					// $(".sidebar-menu-sub li ul").slideUp();
					var img = $(this).find("img");

					$(this).next().slideToggle();
					// $(".sidebar-menu-sub li").removeClass("active");
					// $(".sidebar-menu-sub > li > a > img").attr("src", require("@/assets/images/radio1.png"));

					//console.log("check 3", img.attr("name"));

					if (img.attr("name") === "active") {
						img.attr("src", require("@/assets/images/radio1.png"));
						img.attr("name", "not-active");
					} else {
						img.attr("src", require("@/assets/images/radio2.png"));
						//console.log("check 5", img.attr("src"));
						img.attr("name", "active");
					}
					//console.log("check 4", img.attr("name"));
				} else {
					$(this).next().slideToggle();
					$(".sidebar-menu-sub li").removeClass("active");
					var img = $(this).find("img");

					if (img.attr("name") === "active") {
						img.attr("src", require("@/assets/images/radio1.png"));
						img.attr("name", "not-active");
					} else {
						img.attr("src", require("@/assets/images/radio2.png"));
						img.attr("name", "active");
					}
				}
			});
		});
	},
	beforeDestroy() {
		if (typeof window === "undefined") return;

		window.removeEventListener("resize", this.onResize, { passive: true });
	},
	watch: {
		mini(val) {
			this.$cookies.set("cms_nt_mini_sidebar", val);
		},
		group() {
			this.mobileDrawer = false;
		},
	},
	methods: {
		async checkActive(token) {
			await check_active({
				payload: {
					token: token,
				},
				cbSuccess: (res) => {
					//console.log(res);
					if (res.status === 200 && res.data.message === "Succesfully") {
						if (res.data.data) {
							console.log();
							if (res.data.data.status !== "Active") {
								localStorage.removeItem("token");
								localStorage.removeItem("profile");
								this.$router.push("/login");
							} else {
                                localStorage.removeItem("token");
								localStorage.removeItem("profile");
								localStorage.setItem("token", res.data.token);
								localStorage.setItem("profile", JSON.stringify(res.data.data));
							}
						} else {
							localStorage.removeItem("token");
							localStorage.removeItem("profile");
							this.$router.push("/login");
						}
					} else {
						//console.log(res.data.message);
						this.$toast.error(res.data.message);
						localStorage.removeItem("token");
						localStorage.removeItem("profile");
						this.$router.push("/login");
					}
					this.loading = false;
				},
				cbError: (err) => {
					//console.log(err);
					// this.$toast.error("Email or password invalid!");
					this.$toast.error("Error Code:" + err.response.data.status + "-" + err.response.data.message);
					localStorage.removeItem("token");
					localStorage.removeItem("profile");
					this.$router.push("/login");
					this.loading = false;
				},
			});
		},
		onResize() {
			this.isMobile = window.innerWidth < 700;
		},
		logoutClick() {
			this.confirmLogout = true;
		},
		logoutConfirm() {
			this.confirmLogout = false;
			localStorage.removeItem("token");
			localStorage.removeItem("profile");
			this.$router.push("/login");
		},
	},
};
import "../../styles/sidebar.js";
</script>

<style scoped>
/* .wrapper {
	min-height: 100vh;
	max-width: 100%;
} */
.sidebar-wrap {
	background: rgb(30, 136, 229);
	background: linear-gradient(180deg, #1e88e5 0%, #ceeaff 100%);
}
.layout-wrap {
	height: 100%;
	display: flex;
	flex-direction: column;
	flex: 1;
	position: relative;
}
.layout-header {
	display: flex;
	/* align-items: center; */
	/* justify-content: space-between; */
	height: 196px;
	min-height: 56px;
	/* border-bottom: 1px solid #eaedf3; */
	/* position: absolute; */
	/* right: 0;
	left: 265px; */
	z-index: 10;
	background: rgb(30, 136, 229);
	background: linear-gradient(90deg, rgba(30, 136, 229, 1) 0%, rgba(205, 234, 255, 1) 100%);
	padding: 46px 12px 0 24px;
	color: #fff;
}
.layout-header .header-title {
	display: flex;
	/* align-items: center; */
	width: 100%;
}
.layout-body {
	padding: 0px 16px 16px;
	/* flex: 1; */
	z-index: 20;
	margin-top: -70px;
}
.layout-footer {
	height: 76px;
	min-height: 76px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	border-top: 1px solid #eaedf3;
	padding: 0 16px;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ffffff;
}
.img-logo {
	width: 265px;
	margin: -20px;
	margin-bottom: 10px;
}
.btn-logout {
	position: absolute;
	bottom: 10px;
	left: 20px;
	text-align: center;
	width: 210px;
	border: 1px solid;
	color: #428bca;
	border-radius: 15px;
	padding: 5px;
	cursor: pointer;
	background: #cbe8ff;
}
.main-content {
	background: #ffffff;
	box-shadow: 0px 4px 28px rgb(0 0 0 / 8%);
	border-radius: 20px;
	width: 100%;
	/* margin-top: 153px; */
	z-index: 12;
	min-height: calc(100vh - 132px);
	/* position: absolute;
	top: 150px;
	padding: 20px;
	width: calc(99% - 20px);
	right: 20px;
	left: 20px; */
}
</style>
