<template>
<div>
    <textarea
        v-bind="$attrs"
        rows="4"
        :class="classes"
        :placeholder="placeholder"
        :value="value"
        @input="onInput"
        @keypress="onKeypress"
    />
    <div class="input-detail">
        <div class="message-error">
            <span v-if="error">{{errorMessage}}</span>
        </div>
        <span
            v-if="counter"
            class="counter-number"
        >{{value.length}}/{{counter}}</span>
    </div>
</div>
</template>

<script>
export default {
    name: 'base-input',
    props: {
        placeholder: {
            type: String,
            required: false,
        },
        error: {
            type: Boolean,
            required: false,
        },
        errorMessage: {
            type: String,
            required: false,
        },
        counter: {
            type: String,
            required: false,
        },
        value: {
            type: String,
            required: false,
        },
        name: {
            type: String,
            required: false,
        },
    },
    computed: {
        classes() {
            return {
                'input': true,
                'input-error': this.error,
            };
        },
    },
    methods: {
        onInput(event) {
            this.$emit('input', event.target.value)
        },
        onKeypress(event) {
            this.$emit('onKeypress', event)
        }
    },
}
</script>

<style scoped>
.input {
    width: 100%;
    border: 1px solid #CCD0D1;
    background-color: #ffffff;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    color: #2E2E2E;
    padding: 12px 16px;
    resize: none;
}
.input:focus {
    outline: none;
}
.input.input-error {
    border: 1px solid #FF3535;
}
.input::placeholder {
    color: #CCD0D1;
}
.input:disabled {
    border-color: #CCD0D1;
    background-color: #CCD0D1;
}
.input-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4px;
}
.message-error {
    color: #FF4242;
    font-weight: 400;
    font-size: 12px !important;
    display: block;
}
.counter-number {
    font-weight: 400;
    font-size: 12px !important;
    float: right;
    color: #9EA0A5;
}
</style>