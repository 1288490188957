import Vue from 'vue'

import VueCookies from 'vue-cookies'
import vuetify from '@/plugins/vuetify'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import 'vuetify/dist/vuetify.min.css'

import App from './App.vue'
import router from './router'
import store from './store'

import "@/styles/index.scss"
import "@/utility/components-register"

import HighchartsVue from 'highcharts-vue'

const optionsToast = {
    timeout: 3000,
    closeOnClick: true,
    pauseOnHover: true,
}

console.log(`Version 1.5.0`)

Vue.config.productionTip = false
Vue.use(VueCookies)
Vue.use(Toast, optionsToast)
Vue.use(HighchartsVue);

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
